import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import { MainCanvas } from "components/UI/MainCanvas";
import styled from "styled-components";
import { tsWidth, tsHeight, SliderChicTit } from "@ecomm/typography";
import { Images } from "constants/Images";
import { AccessoriesSlides } from "components/AccessoriesSlides";
import { Navigation } from "components/Navigation";
import { Api } from "api/Api";
import { Entry } from "contentful";
import { ICsTouchscreenProductFields } from "types/contentful";
import { AccessoriesItem } from "types/accessories";
import useProductsByKeys from "commercetools/hooks/useProductsByKeys";

const SlideGlow = styled.div`
  position: absolute;
  ${tsWidth};
  ${tsHeight};
  background-color: #060607;
  /* background-image: url(${Images.globalTopGlow}); */
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
`;

// const AccTitle = styled.div`
//   color: #cdff38;
//   text-transform: uppercase;
//   font-size: 30px;
//   font-family: "Inter", sans-serif;
// `;

const SlideChicTitle = styled.div`
  position: absolute;
  ${tsWidth};
  ${SliderChicTit};
  color: #878b93;
  top: 100px;
  color: white;
  font-size: 28px;
  left: 0px;
  text-align: center;
`;

export const Accessories = (props: RouteComponentProps) => {
  const location = useLocation();
  const locationArray = location.pathname.split("/");

  const intlLocation = locationArray[1];
  let apiLocale = "en-US";
  if (intlLocation === "ca") {
    apiLocale = "en-CA";
  }
  const curSection = locationArray[2];
  const [products, setProducts] = useState<
    Entry<ICsTouchscreenProductFields>[] | null
  >(null);
  const [graphData, setGraphData] = useState<any | null>(null);
  const [slideData, setSlideData] = useState<AccessoriesItem[] | null>(null);

  const ctData = useProductsByKeys(products?.map((p) => p.fields.slug) || [], intlLocation);

  if (!graphData && ctData.products) {
    setGraphData(ctData.products);
  }

  useEffect(() => {
    const fetchProductData = async () => {
      Api.getProducts(apiLocale).then((response) => {
        const products: Entry<ICsTouchscreenProductFields>[] = response.items;
        const screenTypeProducts: Entry<
          ICsTouchscreenProductFields
        >[] = products.reduce(
          (acc: any, obj: Entry<ICsTouchscreenProductFields>) => {
            obj.fields.screenType &&
              // eslint-disable-next-line array-callback-return
              obj.fields.screenType.find((o) => {
                if (o.fields.path === curSection) {
                  acc.push(obj);
                }
              });
            return acc;
          },
          []
        );
        setProducts(screenTypeProducts);
      });
    };
    fetchProductData();
  }, [apiLocale, curSection]);

  useEffect(() => {
    const getPrice = (_variants: any[], slug: string) => {
      const acc = graphData.find((a: any) => a.slug === slug);
      return `${acc?.price?.amount / 100}`;
    };
    const combineData = () => {
      // console.log("graphData", graphData)
      const getProductData = (slug: string) => {
        const ctSlugMap = {
          'peloton-x-camelbak': 'podium-sport-bottle',
          'yoga-blocks': 'yoga-blocks-v2',
          'yoga-strap': 'yoga-strap-v2',
          'yoga-block': 'no-match', // this item is not for sale
        };
        // mapping the slugs due to kronos and ct differences
        // @ts-expect-error
        const matchSlug = ctSlugMap[slug] || slug;
        return products?.find((product) => {
         return product.fields.slug === matchSlug;
        } );
        // return products?.find((product) => product.fields.slug === slug);
      };
      const accessories = graphData.reduce((a: AccessoriesItem[], p: any) => {
        const productData = getProductData(p.slug);
        if (productData) {
          const item: AccessoriesItem = {
            slug: productData.fields.slug,
            title: productData.fields.name,
            description: productData.fields.description,
            slideUrl: productData.fields.slideUrl?.fields.file.url,
            imageUrl: productData.fields.imageUrl?.fields.file.url,
            iconUrl: productData.fields.iconUrl?.fields.file.url,
            iconUrlActive: productData.fields.iconUrlActive?.fields.file.url,
            price: getPrice(p.variants, p.slug), //p.price.amount/100,
            detailOffsetX: productData.fields.detailOffsetX,
            detailOffsetY: productData.fields.detailOffsetY,
            id: productData.fields.id,
            details: productData.fields.details
              ? productData.fields.details
              : [],
            multipleStr: productData.fields.multipleStr,
            alacarteUrl: productData.fields.alacarteUrl?.fields.file.url || "",
          };
          a.push(item);
        }

        return a;
      }, []);
      accessories.sort((a: AccessoriesItem, b: AccessoriesItem) => a.id - b.id);
      setSlideData(accessories);
    };
    if (graphData && products) combineData();
  }, [graphData, products]);

  return (
    <MainCanvas>
      <SlideGlow></SlideGlow>
      {/* <AccTitle>Peloton &nbsp; Accessories</AccTitle> */}
      {slideData && <AccessoriesSlides data={slideData}></AccessoriesSlides>}
      <SlideChicTitle>Accessories a la carte</SlideChicTitle>
      <Navigation></Navigation>
    </MainCanvas>
  );
};
