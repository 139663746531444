import React from "react";
import { HistorySource, Router } from "@reach/router";
import OnRouteChange from "reach-router-scroll-top";
import { Main } from "pages/Main";
import { PackageHome } from "pages/PackageHome";
import { StartScreen } from "pages/StartScreen";
import { Accessories } from "pages/Accessories";
import { SiteMenu } from "pages/SiteMenu";
import { TabletStartScreen } from "pages/TabletStartScreen";
import { createHistory, LocationProvider } from "@reach/router";
import { useReachMemorySource } from "utils/history";
import { NavProvider } from "context/NavContext";
import RouterPage from "components/RouterPage";
import RoutesContainer from "components/RoutesContainer";
import { LocationMenu } from "pages/LocationMenu";
import { Features } from "pages/Features";
import { Classes } from "pages/Classes";
import { Collections } from "pages/Collections";
import { Programs } from "pages/Programs";
import { Memberships } from "pages/Memberships";
import { toCommercetoolsClient } from "commercetools/client";
import { CommercetoolsClientProvider } from "commercetools/provider";

const App = () => {
  const { history, goBack } = useReachMemorySource();
  const ctClient = toCommercetoolsClient();

  const standAlone = window.matchMedia("(display-mode: standalone)").matches;
  const configureHistory = () => {
    const historyWindow = window as unknown as HistorySource;
    return standAlone ? history : createHistory(historyWindow);
  };
  const ReachRouter = Router as unknown as Router;
  const ReachLocationProvider = LocationProvider as unknown as LocationProvider;
  let h = configureHistory();
  return (
    <>
      {/* @ts-expect-error */}
      <ReachLocationProvider refs={[]} history={h}>
        <CommercetoolsClientProvider client={ctClient}>
          <NavProvider standAlone={standAlone} goBack={goBack}>
            {/* @ts-expect-error */}
            <ReachRouter>
              <LocationMenu path="/" />
              <RouterPage path="/:location" pageComponent={RoutesContainer}>
                <RouterPage path="/" pageComponent={SiteMenu} />
                <RouterPage path=":section" pageComponent={StartScreen} />
                <RouterPage path="ipad" pageComponent={TabletStartScreen} />
                <RouterPage
                  path="/:section/main/features"
                  pageComponent={Features}
                />
                <RouterPage
                  path="/:section/main/classes"
                  pageComponent={Classes}
                />
                <RouterPage
                  path="/:section/main/programs"
                  pageComponent={Programs}
                />
                <RouterPage
                  path="/:section/main/collections"
                  pageComponent={Collections}
                />
                <RouterPage
                  path="/:section/main/memberships"
                  pageComponent={Memberships}
                />
                <RouterPage
                  path="/:section/main/:category"
                  pageComponent={Main}
                />
                <RouterPage
                  path="/:section/package/:category/:package"
                  pageComponent={PackageHome}
                />
                <RouterPage
                  path="/:section/accessories/:slide"
                  pageComponent={Accessories}
                />
              </RouterPage>
            </ReachRouter>
            <OnRouteChange />
          </NavProvider>
        </CommercetoolsClientProvider>
      </ReachLocationProvider>
      </>
  );
};

export default App;
